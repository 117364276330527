import React from "react";
// core components
import Button from "/components/CustomButtons/Button.js";


import { getLangFromObj } from "lang/lang.js";

export default function LinkAreaItem({text, href, type, color, locale}){

  if(typeof text === "object")
  {
    text = getLangFromObj(text, locale);
  }

  if(type === "external")
    return (
        <Button href={href} target="_blank" size="sm" color={color}>
            {text}
        </Button>        
    );
  
  return (
    <Button href={href} size="sm" color={color}>
        {text}
    </Button>
    
  );
}