import React from "react";
import GridItem from "/components/Grid/GridItem.js";

import LinkAreaItem from "/components/Footer/LinkAreaItem.js";

import footerStyle from "/styles/jss/bbhost/components/footerStyle.js";

const styles = {
  ...footerStyle,
}

import { trans } from "lang/lang.js";
import { LINKS } from "configs/index";

export default function FooterLinkArea({ locale }) {
  let links = LINKS.map((link, key) => {
    return (
      <LinkAreaItem key={link.name} href={link.link} text={link.text} type={link.type} locale={locale} color="transparent"/>                   
    );
  });
  if(links.length > 0)
    return (
      <GridItem xs={12} sm={12} md={3}>
        <h5>{trans('footer::links.title', locale)}</h5>
        {links}
      </GridItem>
    );
  else
    return (<></>);
}