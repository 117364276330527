import React, { Component } from "react";
import Link from "next/link";
import Image from 'next/image';

import axios from "axios";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "/components/CustomButtons/Button.js";
import GridItem from "/components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";

import typographyStyle from "/styles/jss/bbhost/pages/componentsSections/typographyStyle.js";

import CertificationItem from "/components/Footer/CertificationItem.js";

const styles = {
    ...typographyStyle,
};

const useStyles = makeStyles(styles);

import { trans } from "lang/lang.js";

import { CERTIFICATIONS } from "configs/index";

export default function Certifications({ locale }) {
    const certifications = CERTIFICATIONS.map((certification, key) => {
        return (
            <CertificationItem key={key} name={certification.name} text={certification.subText} href={certification.link}>
                <Image src={certification.logo} alt={certification.text} width={certification.width} height={certification.height} layout="fixed" />
            </CertificationItem>                    
        );
    });
    if(certifications.length > 0)
        return (
            <GridItem xs={6} sm={6} md={2}>
                <h5>{trans('footer::certifications.title', locale)}</h5>
                {certifications}
            </GridItem>
        );
    else
        return (<></>);
}
