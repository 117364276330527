/*eslint-disable*/
import React from "react";
import { useRouter } from 'next/router';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from "/components/Grid/GridContainer.js";
import GridItem from "/components/Grid/GridItem.js";

import styles from "/styles/jss/bbhost/components/footerStyle.js";
import FooterLinkArea from "/components/Footer/FooterLinkArea.js";
import FooterSocialArea from "/components/Footer/FooterSocialArea.js";
import StatusPage from "/components/Functions/StatusPage.js";
import Certifications from "/components/Footer/Certifications.js";
import Button from "/components/CustomButtons/Button.js";

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

import { trans } from "lang/lang.js";
import { CONTATO } from "configs/index";

export default function Footer(props) {
  const classes = useStyles();
  const { locale } = useRouter();
  const { whiteFont, blocked } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const contatArea = CONTATO.map((contato, key) => {
    return (
      <Button key={contato.name} href={contato.route} simple target="_blank" size="sm" color={contato.color}>
        <FontAwesomeIcon icon={contato.icon} size="lg" /> {contato.text}
      </Button>
    );
  });

  if(blocked)
  {
    return (
      <footer className={footerClasses}>
        <div className={classes.container}>
          <GridContainer justifyContent="center" alignItems="center">
            <GridItem xs={12} sm={12} md={6}>            
              <div>
                <StatusPage locale={locale} />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div>
                {trans('footer::copy')} &copy; 2013 - {1900 + new Date().getYear()}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </footer>
    );
  }

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <Certifications locale={locale} />
          <FooterLinkArea locale={locale} />
          <FooterSocialArea locale={locale} />
          <GridItem xs={12} sm={12} md={4}>
            <h5>{trans('footer::contato.title')}</h5>
            {contatArea}
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={6}>            
            <div>
              <StatusPage locale={locale} />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div>
              {trans('footer::copy')} &copy; 2013 - {1900 + new Date().getYear()}
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <Typography variant="subtitle2">
              BB Host LTDA - CNPJ 27.041.737/0001-09
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
  blocked: PropTypes.bool
};
