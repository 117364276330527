import React, { Component } from "react";
import dynamic from 'next/dynamic';

import axios from "axios";

const Button = dynamic(() => import('/components/CustomButtons/Button.js'), { loading: () => <p>Carregando..</p> });
const PriorityHighIcon = dynamic(() => import('@material-ui/icons/PriorityHigh'), { loading: () => <p>Carregando..</p> });
const CheckIcon = dynamic(() => import('@material-ui/icons/Check'), { loading: () => <p>Carregando..</p> });
const WarningIcon = dynamic(() => import('@material-ui/icons/Warning'), { loading: () => <p>Carregando..</p> });
const ErrorOutlineIcon = dynamic(() => import('@material-ui/icons/ErrorOutline'), { loading: () => <p>Carregando..</p> });

import { STATUSPAGE_API } from "constants.js";
import { trans } from "lang/lang.js";

function getAlert(status, locale)
{
    const alert = {
        none: {
            text: trans('footer::status.ok', locale),
            color: "success",
            icon: <CheckIcon />
        },
        ok: {
            text: trans('footer::status.ok', locale),
            color: "success",
            icon: <CheckIcon />
        },
        minor: {
            text: trans('footer::status.warning', locale),
            color: "warning",
            icon: <WarningIcon />
        },
        major: {
            text: trans('footer::status.danger', locale),
            color: "danger",
            icon: <ErrorOutlineIcon />
        },
        critical: {
            text: trans('footer::status.danger', locale),
            color: "danger",
            icon: <ErrorOutlineIcon />
        },
        maintenance: {
            text: trans('footer::status.maintenance', locale),
            color: "info",
            icon: <PriorityHighIcon />
        }
    };

    return alert[status];
}



export default class StatusPage extends Component {
    constructor(props)
    {
        super(props);
        
        this.locale = props.locale;

        this.state = {
            status: [],
            link: ""
        };        
    }

    componentDidMount() 
    {
        this.checkStatus();
    }

    checkStatus()
    {
        const requestConfig = {
            url: `/status.json?timestamp=${new Date().getTime()}`,
            baseURL: STATUSPAGE_API,
            timeout: 1000
        };
        this.axios = axios.create(requestConfig);

        this.axios.request(requestConfig)
            .then( response => {
                const alertObj = getAlert(response.data.status.indicator, this.locale);

                this.setState({
                    status: alertObj,
                    link: response.data.page.url
                });

            });
    }

    getUrl(endpoint)
    {
        return `${STATUSPAGE_API}${endpoint}?timestamp=${new Date().getTime()}`;
    }

    render() 
    {
        return (
            <>
                <Button target="_blank" href={this.state.link} color={this.state.status.color} simple>
                    {this.state.status.icon} {this.state.status.text}
                </Button>
            </>           
        );
    }
}