import React from "react";
import { useRouter } from 'next/router';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// core components
import Button from "/components/CustomButtons/Button.js";

import tooltipsStyle from "/styles/jss/bbhost/tooltipsStyle.js";

const styles = {
  ...tooltipsStyle,
  maxWidth: "500px",
};

const useStyles = makeStyles(styles);
import { getLangFromObj } from "lang/lang.js";

export default function CertificationItem({name, text, href, children}){
  const classes = useStyles();
  
  if(typeof text === "object")
  {
    text = getLangFromObj(text);
  }
  return (
    <Tooltip
        id={ "tooltip-top_" + name } 
        title={text}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button color="transparent" href={href} target="_blank" size="sm" width="50%">
          {children}
        </Button>
      </Tooltip>
  );
}