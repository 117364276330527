import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "/components/CustomButtons/Button.js";
import GridItem from "/components/Grid/GridItem.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import footerStyle from "/styles/jss/bbhost/components/footerStyle.js";

const styles = {
  ...footerStyle,
}

const useStyles = makeStyles(styles);

import { trans } from "lang/lang.js";

import { SOCIAL } from "configs/index";

export default function FooterSocialArea({ locale }) {
    const classes = useStyles();
    const links = SOCIAL.map((link, key) => {
        return (
            <Button key={link.name} href={link.route} simple target="_blank" size="sm" color={link.color}>
                <FontAwesomeIcon icon={link.icon} size="lg" /> {link.text}
            </Button>
        );
    });
    if(links.length > 0)
        return (
            <GridItem xs={6} sm={6} md={3}>
                <h5>{trans('footer::social.title', locale)}</h5>
                {links}
            </GridItem>
        );
    else
        return (<></>);
}
